'use client'

import { IconMidDot } from 'src/assets/icons/IconMiddot'
import { pxToRem } from '@ui/style/muiTheme'
import UIText from '@components/ui/UIText'
import { Box, useTheme } from '@mui/joy'
import { SxProps } from '@mui/joy/styles/types'

export type ContentType =
	| 'LIVE'
	| 'LIVEEND'
	| 'RECENT'
	| 'POPULAR'
	| 'RECOMMENDED'
	| 'POLLING'
	| 'POLLEND'
	| 'EXCLUSIVE'
	| 'PROGRAMEND'
export interface ContentBadgeProps {
	contentType: ContentType
	sx?: SxProps
	liveTagTypeCode?: string
}

/**
 * ContentBadge 컴포넌트
 * 피그마 시안 상의 Badge 컴포넌트를 분류에 상관없이 나타내는 컴포넌트입니다.
 * 필요한 Badge의 타입을 입력해서 노출합니다.
 * @param props.contentType - 필요한 Badge의 타입을 입력합니다. (default 'LIVE')
 * @props 'LIVE'(라이브) | 'LIVEEND'(라이브 종료) | 'RECENT'(NOW) | 'POPULAR'(인기) | 'RECOMMENDED'(추천) | 'POLLING'(진행 중인 Poll) | 'POLLEND'(종료된 Poll) | 'EXCLUSIVE'(회원전용) | 'PROGRAMEND' (종영 프로그램)
 *
 */
const ContentBadge = ({ contentType = 'LIVE', liveTagTypeCode, sx }: ContentBadgeProps) => {
	const { color } = useTheme()

	const getLiveTagText = (code?: string) => {
		switch (code) {
			case 'ONAIR':
				return 'ON AIR'
			case 'ORIGINAL':
				return '오리지널'
			case 'SITE_LIVE':
				return '현장 LIVE'
			case 'ISSUE':
				return '이슈'
			case 'LIVE':
				return 'LIVE'
			default:
				return code
		}
	}

	let badgeContent: string | React.ReactNode
	switch (contentType) {
		case 'LIVE':
			badgeContent = (
				<>
					<IconMidDot
						color={liveTagTypeCode === 'ONAIR' ? color.colWhite : color.colExclusiveRed}
					/>
					<span>{getLiveTagText(liveTagTypeCode)}</span>
				</>
			)
			break
		case 'LIVEEND':
			badgeContent = (
				<>
					<IconMidDot color="#aaa" />
					<span>{getLiveTagText(liveTagTypeCode)}</span>
				</>
			)
			break
		case 'RECENT':
			badgeContent = 'NOW'
			break
		case 'POPULAR':
			badgeContent = '인기'
			break
		case 'RECOMMENDED':
			badgeContent = '추천'
			break
		case 'POLLING':
			badgeContent = '진행 중'
			break
		case 'POLLEND':
			badgeContent = '종료'
			break
		case 'EXCLUSIVE':
			badgeContent = '회원전용'
			break
		case 'PROGRAMEND':
			badgeContent = '종영'
			break
		default:
			badgeContent = ''
	}
	const colorStyles = () => {
		let bgColorValue: string = color.colExclusiveRed
		let textColorValue: string = color.colWhite
		let borderColorValue: string | undefined
		switch (contentType) {
			case 'LIVE':
				bgColorValue = color.colWhite
				textColorValue = color.colBlack

				if (liveTagTypeCode === 'ONAIR') {
					bgColorValue = color.colExclusiveRed
					textColorValue = color.colWhite
				}
				break
			case 'LIVEEND':
				bgColorValue = '#444'
				textColorValue = '#aaa'
				break
			case 'RECENT':
				bgColorValue = color.colPink
				break
			case 'POPULAR':
				bgColorValue = color.colPurple
				break
			case 'RECOMMENDED':
				bgColorValue = color.colBlue
				break
			case 'POLLING':
				bgColorValue = color.colLightBlue
				textColorValue = color.colDarkBlue
				break
			case 'POLLEND':
				bgColorValue = color.colGray2
				textColorValue = color.colGray5
				break
			case 'EXCLUSIVE':
				bgColorValue = 'transparent'
				textColorValue = color.colDarkBlue
				borderColorValue = color.colDarkBlue
				break
			case 'PROGRAMEND':
				bgColorValue = color.colGray3
				textColorValue = color.colGray5
				break
			default:
				bgColorValue = color.colWhite
				textColorValue = color.colBlack
		}
		return {
			whiteSpace: 'nowrap',
			color: textColorValue,
			background: bgColorValue,
			border: borderColorValue && `1px solid ${borderColorValue}`,
			padding: borderColorValue
				? `${pxToRem(5)} ${pxToRem(6)}`
				: `${pxToRem(6)} ${pxToRem(7)}`,
		}
	}
	const sizeStyles = () => {
		let widthValue: string | undefined
		switch (contentType) {
			case 'LIVE':
				// widthValue = pxToRem(59)
				break
			case 'LIVEEND':
				// widthValue = pxToRem(49)
				break
			case 'RECENT':
				widthValue = pxToRem(44)
				break
			case 'POPULAR':
				widthValue = pxToRem(35)
				break
			case 'RECOMMENDED':
				widthValue = pxToRem(35)
				break
			case 'POLLING':
				widthValue = pxToRem(48)
				break
			case 'POLLEND':
				widthValue = pxToRem(35)
				break
			case 'EXCLUSIVE':
				widthValue = pxToRem(56)
				break
			case 'PROGRAMEND':
				widthValue = pxToRem(35)
				break
			default:
				widthValue = 'auto'
		}
		return {
			width: widthValue,
		}
	}
	return (
		<Box
			display="inline-flex"
			borderRadius={pxToRem(4)}
			className="contentBadge"
			sx={{ ...colorStyles(), ...sizeStyles(), ...sx }}
		>
			<UIText
				className="contentBadge_text"
				component="p"
				display="flex"
				alignItems="center"
				fontSize={pxToRem(12)}
				lineHeight={pxToRem(12)}
				fontWeight="700"
				color="inherit"
				sx={{ '&> *:not(:first-child)': { marginLeft: pxToRem(4) } }}
			>
				{badgeContent}
			</UIText>
		</Box>
	)
}
export default ContentBadge
