import { TModal } from '@components/layout/Modal/ConfirmModal'
import { uuidv4 } from '@utils/random'
import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

interface ConfirmState {
	confirm?: Array<TModal>
}

export const confirmState = atom<ConfirmState>({
	key: '#confirm',
	default: {
		confirm: undefined,
	},
})

export const useConfirmControl = () => {
	const [state, setState] = useRecoilState<ConfirmState>(confirmState)

	const setConfirm = useCallback(
		(value?: TModal) => {
			if (value) {
				// setState((prev) => ({
				// 	confirm: [...(prev.confirm ?? []), { id: uuidv4(), ...value }],
				// }))
				setState((prev) => {
					// 기존 모달을 필터링하여 동일한 id를 가진 모달을 제거
					const existingModals = prev.confirm?.filter(({ id }) => id !== value.id)
					// 새로운 모달을 추가하여 상태를 업데이트
					return {
						confirm: [...(existingModals ?? []), { id: uuidv4(), ...value }],
					}
				})
			}
		},
		[setState],
	)
	const setOnceConfirm = useCallback(
		// 모달 중복 금지
		(value?: TModal) => {
			if (value) {
				setState({
					confirm: [{ id: uuidv4(), ...value }],
				})
			}
		},
		[setState],
	)
	const closeConfirm = useCallback(
		(value?: string) => {
			if (value) {
				setState((prev) => ({
					confirm: [...(prev.confirm ?? []).filter(({ id }) => id !== value)],
				}))
			}
			if (value === 'close') {
				setState({
					confirm: [],
				})
			}
		},
		[setState],
	)
	return { confirm: state.confirm, setConfirm, setOnceConfirm, closeConfirm }
}
