import { useRouter } from 'next/navigation'
import nProgress from 'nprogress'

import { useConfirmControl } from '@store/confirm'
import { useOpenHeaderState } from '../Header/store/store'
import { TModal } from './ConfirmModal'

const useModal = () => {
	const router = useRouter()
	const { setConfirm, setOnceConfirm } = useConfirmControl()
	const handleSettingOpen = useOpenHeaderState('settingOpen')
	const open = (props: TModal) => {
		setConfirm(props)
	}
	const login = ({ onCancel, targetUrl }: { onCancel?: () => void; targetUrl?: string } = {}) => {
		setConfirm({
			id: '#login',
			message: '로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?',
			buttons: [
				{
					text: '취소',
					onClick: (close) => {
						close()
						if (onCancel) {
							onCancel()
						}
					},
				},
				{
					text: '로그인',
					onClick: (close) => {
						close()
						nProgress.start()
						router.push(
							`${
								process.env.NEXT_PUBLIC_AUTH_LOGIN_URL
							}?targetUrl=${encodeURIComponent(targetUrl ?? window.location.href)}`,
						)
					},
				},
			],
		})
	}
	const netWork = (isOnce: boolean = false) => {
		const info = {
			title: '데이터 사용 알림',
			message: 'LTE/5G 망에서 방송을 시청 하시려면,\n설정에서 LTE/5G 사용 설정을 해주세요.',
			buttons: [
				{ text: '취소' },
				{
					text: '설정',
					onClick: (close: () => void) => {
						handleSettingOpen()
						close()
					},
				},
			],
		}
		if (isOnce) {
			setOnceConfirm(info)
		} else {
			setConfirm(info)
		}
	}
	return {
		open,
		login,
		netWork,
	}
}
export default useModal
