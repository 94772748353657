import { useTheme } from '@mui/joy'

export const IconMidDot = ({ color }: SVGComponentProps) => {
	const theme = useTheme()
	const iconColor = color || theme.color.colWhite
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
			<circle cx="3.5" cy="4" r="3.5" fill={iconColor} />
		</svg>
	)
}
